import $ from 'jquery';

import {initMap } from "./map";
import './faq'
import './infringement'
import './block-documents'
import './block-news-articles'
import './spot-list'

window.initMap = initMap;

/* MOBILE MENU TOGGLE */
var navIcon = document.getElementById('nav-icon');
if (navIcon) {
    navIcon.addEventListener('click', function () {
        var nav = document.getElementsByTagName('nav')[0];

        if (navIcon.classList.contains('open')) {
            navIcon.classList.remove('open');
            nav.classList.remove('nav-mobile-show');
            $('#legend').show();
        } else {
            navIcon.classList.add('open');
            nav.classList.add('nav-mobile-show');
            $('#legend').hide();
        }
    });
}

/* MENU FIX */

if (window.location.hash) {
    var $menuItem = null;

    switch (window.location.hash) {
        case '#0':
            $menuItem = $('.menu > ul > li:nth-child(2)');
            break;
        case '#1':
            $menuItem = $('.menu > ul > li:nth-child(1)');
            break;
    }

    if ($menuItem) {
        $menuItem.removeClass('current-menu-ancestor');
        $menuItem.find('li').removeClass('current-menu-item');
    }
}

/* INFO BOX */

var $closeInfoButtons = $('.close-info');
for (var i = 0; i < $closeInfoButtons.length; i++) {
    (function($closeInfoButton) {
        $closeInfoButton.click(function(e) {
            e.preventDefault();

            var $parent = $(this).closest('div').parent();
            $parent.hide();

            var d = new Date();
            var expires = '';

            var id = $parent.prop('id');
            switch (id) {
                case 'status-info':
                    d.setTime(d.getTime() + (24*60*60*1000)); // Hide for 1 hour
                    expires = "expires="+ d.toUTCString() + ";";
                    document.cookie = id + "=hide; " + expires + " path=/";
                    break;
                case 'map-info':
                    var content = $parent.find('.content').text();
                    d.setTime(d.getTime() + (60*60*1000)); // Hide for 1 hour
                    expires = "expires="+ d.toUTCString() + ";";
                    document.cookie = id + "=" + content + "; " + expires + " path=/";
                    break;
            }

        });
    })($($closeInfoButtons[i]));
}

/* MENU DELAY HANDLING */
var delay = 100;

var $selectedElement = null;
var $queuedElement = null;

var $menuItems = $('nav.menu > ul > li');
var timeout = null;

var $menuLinkItems = $('nav.menu > ul > li > a');
var linkTest = document.querySelectorAll('nav.menu > ul > li > a')
console.log($menuLinkItems)

$menuItems.hover(function(e) {
    var $this = $(this);

    $this.addClass('hover');

    // Clear timeout if present
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }

    // If we selected another menu element, remove hover from the existing one
    if ($selectedElement && $selectedElement[0] !== $this[0]) {
        $selectedElement.removeClass('hover');
    }

    $selectedElement = $this;
}, function(e) {
    var $this = $(this);

    // Add a timeout before we remove the selection in case the user
    // just moved their cursor down to the sub menu
    timeout = setTimeout(function() {
        // Remove the hover if we still have selected the same element
        if ($selectedElement && $this[0] === $selectedElement[0]) {
            $this.removeClass('hover');
        }

        $selectedElement = null;
    }, delay);
});

$menuLinkItems.on('focus', function(e) {
    var $this = $(this);
    var thisParent = $this.parent();
    console.log('blokk 1');

    $menuItems.each(function (index){
        $menuItems[index].classList.remove('hover')
        //$menuItems[index].removeClass("hover");   
    });

    thisParent.addClass('hover');

    // Clear timeout if present
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }

    // If we selected another menu element, remove hover from the existing one
    if ($selectedElement && $selectedElement[0] !== $this[0]) {
        $selectedElement.removeClass('hover');
    }

    $selectedElement = $this;
});


var $currentTapMenuItem = null;
$menuItems.click(function(e) {
    var $this = $(this);
    $currentTapMenuItem = $this;
});

function isTouchDevice() {
    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    var mq = function(query) {
        return window.matchMedia(query).matches;
    }

    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
        return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
}
