var Flickity = require('flickity');

// Add this code:

Flickity.createMethods.push('_createPrevNextCells');

Flickity.prototype._createPrevNextCells = function() {
    this.on( 'select', this.setPrevNextCells );
};

Flickity.prototype.setPrevNextCells = function() {
    // remove classes
    changeSlideClasses( this.previousSlide, 'remove', 'is-previous' );
    changeSlideClasses( this.nextSlide, 'remove', 'is-next' );
    // set slides
    this.previousSlide = this.slides[ this.selectedIndex - 1 ];
    this.nextSlide = this.slides[ this.selectedIndex + 1 ];
    // add classes
    changeSlideClasses( this.previousSlide, 'add', 'is-previous' );
    changeSlideClasses( this.nextSlide, 'add', 'is-next' );
};

function changeSlideClasses( slide, method, className ) {
    if ( !slide ) {
        return;
    }
    slide.getCellElements().forEach( function( cellElem ) {
        cellElem.classList[ method ]( className );
    });
}

var options = {
    cellAlign: "center",
    adaptiveHeight: false,
    wrapAround: true,
    pageDots: false,
    draggable: false,
};

var flickity = null;

function setupFlickity() {
    if (window.innerWidth < 900 && !flickity) {
        if ($('.news-articles > div').length > 0) {
            flickity = new Flickity('.news-articles > div', options);
        }

    }

    if (window.innerWidth >= 900 && flickity) {
        flickity.destroy();
        flickity = null;
    }

    if (flickity) {
        flickity.resize();
    }
}

setupFlickity();

$(window).resize(setupFlickity);


