import moment from 'moment';
let spotList = $('#spot-list');
console.log(spotList.length);
if(spotList.length > 0) {
    var timedCache = cacheTime;
    var twoMinuteLess = moment.unix(timedCache).format('DD.MM.YYYY, HH:mm')

    for (var key of Object.keys(availableParkingSpots)) {
        spotList.append(`<li><ul> <li>${key} </li> <li>${availableParkingSpots[key]}</li> <li> ${twoMinuteLess}</li></ul></li>`);
    }
}