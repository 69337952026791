var $infringementSelect = $('#infringement-id');
var $infringementNextButton = $('#infringement-next');


$infringementNextButton.click(function(e) {
    e.preventDefault();
    
    var id = $infringementSelect.val();
    window.location = '/?p=' + id;
});

$infringementNextButton.on('focus', function(e) {
    $(document).on('keypress',function(e) {
        if(e.which == 13) {
            var id = $infringementSelect.val();
            window.location = '/?p=' + id;
        }
    });
  });