import $ from 'jquery'

var $documents = $('.documents');
for (var i = 0; i < $documents.length; i++) {

    (function($documentsDiv) {
        var $showMore = $documentsDiv.find('.show-more');
        $showMore.find('a').click(function(e) {
            e.preventDefault();

            $documentsDiv.find('.row').removeClass('hide');
            $showMore.addClass('hide');
        });
    })($($documents[i]));
}